import * as React from "react";

import { Layout } from "components";
import {
  AgencyBankingFeatures,
  AgencyBankingPageHeader,
} from "templates/solutions/agency-banking";

const AgencyBankingPage = () => (
  <Layout title="Agency Banking">
    <AgencyBankingPageHeader />
    <AgencyBankingFeatures />
  </Layout>
);

export default AgencyBankingPage;
