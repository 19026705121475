import React from "react";
import { PageFeatures } from "components";
import { StaticImage } from "gatsby-plugin-image";

const AgencyBankingFeatures = () => {
  return (
    <div>
      <PageFeatures
        features={[
          "Withdraw cash  ",
          "Deposit Cash",
          "Perform bill payment",
          "Quick Access to POS Terminals",
          "24/7 Customer Support",
        ]}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/agency-banking/agency-banking.jpg"
            alt="agency-banking"
          />
        }
      />
    </div>
  );
};

export { AgencyBankingFeatures };
