import { PageHeader, Br } from "components";
import React from "react";

const AgencyBankingPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          {" "}
          Shorten time to market for <Br on="desktop" /> your{" "}
          <span className="text-primary">
            Agency Banking Solution <Br on="all" />
          </span>{" "}
        </>
      }
      subheading={
        <>
          {" "}
          Build a network of super agents with a ready toolkit–front-end,
          middleware and <Br on="desktop" />
          core banking systems to offer seamless banking experience from a POS
          terminal.
        </>
      }
      button={{ text: "Build your bank", to: "https://www.readycash.com.ng/" }}
    />
  );
};

export { AgencyBankingPageHeader };
